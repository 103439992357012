<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Pricing</h2>
            </CCardHeader>
            <CCardBody>
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <price-grid :key="gridReload" :editClick="editClick" :deleteClick="deleteClick"/>
                <price-form ref="priceForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import priceGrid from './Grid/PriceGrid.vue';
import priceForm from './Component/PriceForm.vue';
import store from '../../../store';
import PriceServices from './Script/PriceServices.js';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'Price',
    components: {
        'price-grid': priceGrid,
        'price-form': priceForm
    },
    mounted () {
        var permission = this.$globalfunc.permissionModule('Pricing');
        document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        addClick(){
             this.$refs.priceForm.addClick();
        },
        editClick(priceData, view){
            this.$refs.priceForm.editClick(priceData, view);
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id
                }
                if (result.isConfirmed == true) {
                    PriceServices.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>