import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class PriceService {
    readPriceQuery(){
        var query = `
            query ($paging:ServerPaging) {
                GetPriceCategory (Paging:$paging) {
                    ItemPriceCategory {
                        created_at
                        last_update
                        price_category_id
                        price_category_name
                        price_type
                        price_category_detail{
                            price_category_id
                            price_category_name
                            item_id
                            product_code
                            item_name
                            barcode
                            item_type
                            active_flag
                            type_detail_id
                            uom_conv
                            price_retail
                            price_project
                        }
                    }
                    Total
                } 
            }
        `;
        return query;
    }

    async getPriceDetail(id){
        var variables = {
            id : id
        }
        var query = gql`
            query ($id:String) {
                GetPriceCategory (PCI:$id) {
                    ItemPriceCategory {
                        created_at
                        last_update
                        price_category_id
                        price_category_name
                        price_type
                        price_category_detail{
                            price_category_id
                            price_category_name
                            item_id
                            product_code
                            item_name
                            barcode
                            item_type
                            active_flag
                            type_detail_id
                            uom_conv
                            price_retail
                            price_project
                        }
                    }
                    Total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetPriceCategory.ItemPriceCategory[0].price_category_detail;
    }

    async addQuery(variables){
        var query = gql`
            mutation ($data:NewItemPriceCategory!) {
                CreatePriceCategory (New:$data) {
                    response
                }
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`
            mutation ($id:String!, $data:NewItemPriceCategory!) {
                UpdatePriceCategory (PriceCategoryID:$id, New:$data) {
                    response
                }
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`
            mutation ($id:String!) {
                DeletePriceCategory (PriceCategoryID:$id) {
                    response
                }
            }
        `;

        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
}

export default new PriceService();